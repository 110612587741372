$primary: #20A2E8;
$primary-border: #54A0E2;
$white: #ffffff;
$light-grey: #F1F4F5;
$dark-grey: #474950;
$grey: #707070;
$black: #000000;
$image-background: #4E6285;
$navy-blue: #11232E;
$lighter-black: #192C49;
