@import '../../styles/shared';

.home-page {
  .product-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: $image-background;
    min-height: 500px;

    .product-image {
      max-height: 500px;
      margin-top: 15px;
    }

    .product-description {
      color: $white;
      padding-left: 60px;

      .title,
      .subtitle,
      .description {
        width: 100%;
        margin: 0;
      }

      .title {
        font-size: 55px;
        font-weight: bold;
      }

      .subtitle {
        height: 36px;
        font-size: 36px;
        font-weight: normal;
        margin-bottom: 20px;
      }

      .description {
        height: 20px;
        font-size: 20px;
        font-weight: lighter;
      }
    }
  }

  .primary-blue-filter {
    img {
      filter: invert(61%) sepia(64%) saturate(4162%) hue-rotate(171deg) brightness(99%) contrast(83%);
      -o-filter: invert(61%) sepia(64%) saturate(4162%) hue-rotate(171deg) brightness(99%) contrast(83%);
    }
  }

  .separator {
    height: 10px;
    background-color: $primary;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      position: relative;
      text-transform: uppercase;
      margin: -1px;
      padding: 6px 20px 16px;
      color: $white;
      background-color: $primary;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 39px solid transparent;
        top: 0;
      }

      &:before {
        border-right: 39px solid $primary;
        left: -38px;

      }

      &:after {
        border-left: 39px solid $primary;
        right: -38px;
      }
    }
  }

  $circleDimension: 50px;

  .tabs-wrapper {
    margin-top: 95px;

    .tab-headers {
      width: 100%;
      position: relative;

      .tab-header {
        flex: 1;
        position: relative;

        .tab-title {
          text-align: center;
          font-size: 18px;
          font-weight: normal;
          margin: 25px 0;
          color: $dark-grey;
        }

        .tab-bubble {
          border: 1px solid $dark-grey;
          background-color: $white;
          border-radius: 50%;
          position: relative;
          font-weight: bold;
          color: $dark-grey;
          cursor: pointer;
          font-size: 20px;
          height: $circleDimension;
          width: $circleDimension;
          z-index: 1;

          &:not(.can-click) {
            cursor: not-allowed;
          }
        }

        &.active {
          .tab-bubble {
            border: 1px solid $primary;
            background-color: $primary;
            color: $white;
          }

          .tab-title {
            color: $primary;
            font-weight: bold;
          }
        }

        &.inactive {
          .tab-bubble {
            background-color: $dark-grey;
            color: $white;
          }
        }


        &:nth-child(2) {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .bubble-separator {
          position: absolute;
          width: 50%;
          margin-top: $circleDimension / 2;
          border-top: 2px solid $dark-grey;

          &.left {
            left: 0;
          }

          &.right {
            right: 0;
          }
        }
      }
    }

    .d-grid {
      display: grid;
    }

    .tw-4 {
      grid-template-columns: repeat(4, 1fr);
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .tabs-description {
      margin-bottom: 50px;
      color: $dark-grey;

      h5 {
        font-size: 18px;
        font-weight: normal;
      }
    }

    .tabs-content {
      margin-top: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $dark-grey;

      &.custom-width {
        max-width: 800px;
      }

      .text-center {
        text-align: center;
      }

      .subtitle {
        font-size: 24px;
        margin: 0 0 10px;
        font-weight: lighter;
      }

      .title {
        margin: 0;
        font-size: 43px;
        font-weight: bold;
      }

      .grey-content {
        background-color: $light-grey;
        padding: 20px 18px;

        &.mb-35 {
          margin-bottom: 35px;
        }

        &.mt-35 {
          margin-top: 35px;
        }

        .option {
          margin: 18px;
          padding: 30px;
          border: 5px solid $white;
          background-color: $white;
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;

          .icon-wrapper {
            width: 53px;
            height: 53px;
            margin-bottom: 32px;

            img {
              height: 100%;
              width: 100%;
            }
          }

          .option-title {
            font-size: 20px;
            margin: 0;
            text-transform: uppercase;
          }

          .option-description {
            font-size: 18px;
            margin: 0;
            padding: 5px;
          }

          &.active {
            border: 5px solid $primary;
          }
        }
      }

      .tab-icon-wrapper {
        font-size: 24px;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: $primary;

        &.mt-60 {
          margin-top: 60px;
        }

        &.mb-35 {
          margin-bottom: 35px;
        }

        svg {
          margin-right: 10px;
          width: 28px;
          height: 31px;
          padding-bottom: 7.5px;

          image {
            width: 31px;
            height: 28px;
          }

          path {
            fill: $primary;
          }
        }
      }

      .tab-price-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 23px;

        .price,
        .currency {
          text-align: left;
        }

        .price {
          width: 50%;
          background-color: $primary;
          color: $white;

          h3 {
            margin: 10px;
          }
        }

        .currency {
          width: 50%;

          select {
            color: $dark-grey;
            height: 40px;
            width: 100%;
            border-left: 0;
            outline: none;
            font-size: 18px;
            font-weight: bold;
            background-color: $white;
          }
        }
      }

      .promo-code-wrapper {
        width: 100%;
        text-align: left;
        font-size: 18px;
        margin-bottom: 50px;
      }

      .device-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;

        .title {
          font-size: 24px;
          font-weight: normal;
          margin-bottom: 25px;
        }

        .input-wrapper {
          .error {
            position: absolute;
            font-size: 12px;
            color: red;
            bottom: -15px;
            margin: 0;
          }

          .error-stripe {
            position: absolute;
            font-size: 12px;
            color: red;
            bottom: 0;
            margin: 0;
          }
        }
      }

      .tab-1 {
        max-width: 800px;
        width: 100%;
      }

      .tab-2,
      .tab-3 {
        .space {
          margin-left: 40px;
        }
        .total-amount, .total-discount {
          font-size: 20px;
          font-weight: bold;
          padding: 0;
        }
        .card-label {
          display: block;
          font-size: 14px;
          margin-bottom: 10px;

          span {
            font-weight: bold;
            color: $primary;
          }
        }

        width: 100%;

        .payment-title,
        .contact-title {
          font-size: 24px;
        }

        .grouped {
          .input-wrapper {
            &:first-child {
              margin-right: 12px;
            }
          }
        }

        .credit-card,
        .radio-wrapper {
          margin-bottom: 30px;
        }

        //.credit-card {
        //  height: 40px;
        //  position: relative;
        //  border: 1px solid rgba($grey, 0.3);
        //  display: flex;
        //
        //  input {
        //    height: 100%;
        //    margin: 0;
        //    padding: 0;
        //    border: none;
        //    outline: none;
        //
        //    &.card {
        //      padding: 0 25px;
        //      width: 100%;
        //    }
        //
        //    &.expiration,
        //    &.cvv {
        //      width: 50%;
        //    }
        //  }
        //
        //  .custom-card {
        //    width: 100%;
        //
        //    .custom-placeholder {
        //      pointer-events: none;
        //      position: absolute;
        //      height: 100%;
        //      display: flex;
        //      align-items: center;
        //      left: 25px;
        //      color: $grey;
        //
        //      svg {
        //        padding-left: 20px;
        //        padding-right: 5px;
        //
        //        path {
        //          fill: $grey;
        //        }
        //      }
        //    }
        //  }
        //}

        .terms-wrapper {
          margin-bottom: 30px;
          display: flex;

          input[type="checkbox"] {
            height: 20px;
            width: 30px;
            color: #20A2E8;
            margin: 0 22px 0 0;
          }

          span {
            font-weight: bold;
            color: $primary;
          }
        }

        .preview {
          font-size: 43px;
          margin-top: 0;
        }

        .title {
          margin: 30px 0;
          font-size: 24px;
        }

        .your-device,
        .selected-function {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
          color: rgba($dark-grey, 0.5);
        }

        .selected-function {
          margin-top: 30px;
        }

        .price-wrapper {
          font-size: 18px;
          height: 40px;
        }

        .promo-code {
          margin: 20px 0;
          height: 40px;
          display: flex;
          font-size: 16px;

          input {
            height: 39px;
            border: none;
            padding: 0 20px;
            width: 100%;
            outline: none;
          }

          button {
            cursor: pointer;
            font-weight: bold;
            height: 100%;
            white-space: nowrap;
            border: none;
            background-color: $primary;
            color: $white;
          }
        }

        .promo-code-delete {
        font-size: 16px;
        margin-top: 20px;
        background-color: $white;
        text-align: center;
        line-height: 37px;
        height: 37px;
        padding-left: 18px;
        border-radius: 50px;
        .promo-code-delete-wrapper {
          .code p {
            font-weight: bold;
          }
          .discount {
            margin-left: 45px;
            p {
              font-weight: bold;
            }
          }
        }
        .reduced-price {
          align-items: center;
          font-weight: bold;
        }
      }

        .promo-calculations {
          border-bottom: 1px solid rgba($grey, 0.3);
          padding: 0 18px;
          .subtotal,
          .discount,
          .shipping,
          .hst {
            margin: 10px 0;
            font-size: 16px;
          }
        }

        .total {
          margin-top: 16px;
          font-size: 20px;
          font-weight: bold;
          padding: 0 18px;
        }
      }
    }

    .buttons-wrapper {
      width: 100%;
      margin-bottom: 150px;
      display: flex;
      justify-content: space-between;

      &.flex-end {
        justify-content: flex-end;
      }

      button {
        height: 40px;
        width: 254px;
        text-transform: uppercase;
        border: none;
        background-color: $dark-grey;
        color: $white;
        font-size: 18px;
        cursor: pointer;

        &.next {
          background-color: $primary;
        }
      }
    }

    .input-wrapper {
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      position: relative;


      .error {
        position: absolute;
        font-size: 12px;
        color: red;
        bottom: -15px;
        margin: 0;
      }

      .error-stripe {
        position: absolute;
        font-size: 12px;
        color: red;
        bottom: 0;
        margin: 0;
      }

      label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;

        span {
          color: $primary;
        }
      }

      input {
        height: 40px;
        font-size: 16px;
        padding: 0 25px;
        border: 1px solid rgba($grey, 0.3);
        outline: none;
      }

      .calendar-input-wrapper {
        .calendar-input {
          display: flex;
          align-items: center;
          border: 1px solid rgba($grey, 0.3);
          padding: 0 25px;

          svg {
            path {
              fill: $dark-grey;
            }
          }
        }
      }
    }
  }

  .congratulations-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(#000000, 0.2);
    }

    .overlay,
    .close-button {
      cursor: pointer;
    }

    .modal {
      position: relative;
      z-index: 1;
      background-color: $white;
      border-radius: 25px;
      max-width: 700px;
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: $dark-grey;

      .close-button {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 39px;
        height: 39px;
      }

      .title {
        font-size: 43px;
        margin-bottom: 36px;
      }

      .description {
        font-size: 24px;
        max-width: 440px;
        margin-bottom: 51px;
      }

      .activation-code {
        box-shadow: 0 4px 8px #00000017;
        border: 1px dashed $grey;
        border-radius: 63px;
        padding: 23px;
        max-width: 450px;
        width: 100%;
        margin-bottom: 100px;

        .code-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 29px;
          font-weight: bold;

          span {
            margin-left: 10px;
            color: $primary;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .product-wrapper {
      .product-description {
        padding-left: 0;
        text-align: center;

        .title {
          height: 100%;
        }
      }

      .product-image {
        height: auto;
        padding-right: 0;
        width: 100vw;
      }
    }

    .tabs-wrapper {
      .tab-headers {
        .tab-header {
          padding-left: 15px;
          padding-right: 15px;

          .tab-title {
            text-align: center;
          }
        }
      }

      .buttons-wrapper.flex-end {
        margin-bottom: 35px;
      }

      .buttons-wrapper {
        margin-bottom: 35px;

        &.flex-end {
          justify-content: center;
        }
      }

      .tw-4 {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .title-wrapper {
      .title {
        width: 100%;
        text-align: center;

        &:before,
        &:after {
          content: none;
        }
      }
    }

    .grey-content {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 576px) {
    $circleDimension: 33px;

    .title-wrapper {
      .title {
        width: 100%;
        text-align: center;
        font-size: 16px;
        height: 38px;
        margin: 0;
      }
    }

    .product-wrapper {
      display: flex;
      align-content: space-between;


      .product-description {
        text-align: center;
        padding-left: 0;
        align-self: baseline;
        margin-top: 43px;

        .product-description-mobile {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 40px;
            margin: 0;
            padding: 0;
            width: 322px;
          }

          .subtitle {
            height: 30px;
            font-size: 26px;
            margin-bottom: 20px;
          }

          .description {
            height: 16px;
            font-size: 14px;
            font-weight: lighter;
          }
        }
      }
    }

    .tabs-wrapper {
      margin-top: 60px;
      padding: 0;

      .tabs-description {
        max-width: 322px;
        width: 100%;

        h5 {
          padding: 0 25px;
        }

      }

      .tabs-description h5 {
        font-size: 16px;

      }

      .tab-headers {
        .tab-header {
          flex: 1;
          padding-left: 25px;
          padding-right: 25px;

          .tab-title {
            font-size: 12px;
            height: 20px;
            text-align: center;
          }

          .tab-bubble {
            font-size: 13px;
            height: $circleDimension;
            width: $circleDimension;
          }

          .bubble-separator {
            margin-top: $circleDimension / 2;
          }
        }
      }

      .tabs-content {
        margin-top: 30px;

        .subtitle {
          font-size: 16px;
        }

        .title {
          font-size: 24px;
        }

        .grey-content {
          padding: 0;

          &.mb-35 {
            margin-bottom: 0;
          }

          .option {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 35px 28px 22px 25px;

            .option-title {
              padding: 0;
            }

            &.cursor-pointer {
              margin-bottom: 16px;
            }
          }
        }

        .tab-icon-wrapper {
          align-self: center;

          &.mt-60 {
            margin-top: 20px;
          }

          &.mb-35 {
            margin-bottom: 30px;
          }
        }

        .tab-price-wrapper {
          max-width: 322px;
          width: 100%;
          flex-direction: column;

          .price {
            font-size: 18px;
            width: 100%;
          }

          .currency {
            width: 100%;

            select {
              border-left: 1px solid $grey;
              height: 37px;
              font-size: 18px;
            }
          }
        }

        .promo-code-wrapper {
          max-width: 280px;
          width: 100%;
          font-size: 16px;
          text-align: center;
        }

        .device-wrapper {
          max-width: 322px;
          width: 100%;

          .input-wrapper {
            padding: 0;
          }
        }

        .tab-2 {
          .tab-icon-wrapper {
            padding: 0 22px 0 29px;
            width: 100%;
          }

          .promo-code {
            flex-direction: column;
            justify-content: space-between;
            height: 102px;
            padding: 0 27px 0 29px;

            input {
              height: 40px;
              padding: 0;
              width: auto;
            }

            button {
              height: 40px;
            }
          }

          .selected-function {
            padding: 0 27px 0 29px;
          }

          .price-wrapper {
            padding: 0;
            margin: 0 25px 0 0;
            display: flex;
            align-items: center;
            max-width: 81px;
            width: 100%;
          }

          .your-device {
            padding: 29px 0 0 27px;
          }

          .title {
            padding: 0 27px 0 29px;
            font-size: 24px;
            margin: 30px 0 0 0;
          }

          .serial-number {
            padding: 0 0 0 29px;
            margin: 0;
            font-size: 17px;
          }

          .promo-code-delete{
            padding: 19px 9px 10px 29px;
            border-radius: unset;
            height: 100%;
            line-height: unset;

            .promo-code-delete-wrapper {
              flex-direction: column;
              .code {
                height: 19px;
                p {
                  margin: 0;
                }
                div{
                  height: 19px;
                }
              }
              .discount {
                margin: 0;
                align-self: baseline;
                height: 19px;
                p {
                  margin: 0;
                }
                div{
                  height: 19px;
                }
              }
            }
            .reduced-price {
              font-weight: bold;
              align-self: baseline;
            }
          }

          .promo-calculations {
            padding: 0px 27px 0 29px;
          }

          .total {
            padding: 0 27px 27px 29px;
          }
        }

        .tab-3 {
          .total-discount, .total-amount {
            padding-left: 27px;
            padding-bottom: 42px;
          }
          .grouped {
            margin-right: 0;
            flex-direction: column;
          }

          .preview {
            padding: 30px 0 0 27px;
          }

          .your-device {
            padding: 17px 0 0 27px;
          }

          .title {
            padding: 10px 0 0 27px;
            margin: 0;
          }

          .serial-number {
            padding: 0 0 0 27px;
            margin: 0;
            font-size: 17px;
          }

          .selected-function {
            margin: 0;
            padding: 39px 0 0 27px;
          }

          .tab-icon-wrapper {
            padding: 14.31px 0 0 27px;
          }

          .total {
            padding: 0 0 40px 27px;
          }

          .contact-title {
            padding: 0 0 0 27px;
          }

          .input-wrapper {
            padding: 0 27px 0 27px;
            width: auto;
          }

          .payment-title {
            padding: 0 29px 0 27px;
            font-size: 22px;
          }

          .radio-wrapper {
            padding: 14px 27px 0 27px;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-self: center;

            input[type="radio"] {
              height: 20px;
              width: 20px;
              color: $primary;
              margin: 0 22px 0 0;
            }
          }

          .second-radio {
            margin: 20px 0 20px;
          }

          .terms-wrapper {
            padding: 0 27px 0 27px;
            display: flex;

            input[type="checkbox"] {
              height: 20px;
              width: 30px;
              color: $primary;
              margin: 0 22px 0 0;
            }

            label {
              font-size: 16px;
            }
          }

          .credit-card {
            padding: 0 27px 0 27px;
            margin-top: 17px;
            margin-bottom: 13px;
            border: none;
            height: 130px;
            display: flex;
            flex-wrap: wrap;

            label {
              font-size: 16px;
              margin-bottom: 5px;

              span {
                color: $primary-border;
              }
            }

            .custom-card {
              border: 1px solid $black;
              height: 40px;
              display: flex;

              .custom-placeholder {
                padding-left: 27px;
                height: 40px;
                align-self: auto;
              }

              .card {
                padding: 0 0 0 26px;
              }
            }

            .grouped {
              display: flex;
              flex-direction: row;
              border: 1px solid $black;
              height: 40px;
              width: 100%;

              .expiration {
                height: 40px;
                flex: 1;
                padding: 0 0 0 26px;
              }

              .cvv {
                height: 40px;
                flex: 1;
                margin: 0;
              }
            }
          }
        }

        .buttons-wrapper {
          max-width: 322px;
          width: 100%;


          button {
            width: 155px;
            font-size: 16px;
          }

          &.background {
            margin-bottom: 0;
            padding: 0 26px 35px 27px;
          }

          &.flex-end {
            justify-content: center;
            margin-bottom: 0;
            padding: 8px 28px 35px 25px;

            button {
              width: 100%;
            }
          }
        }
      }


      .input-wrapper {
        padding: 0 27px 0 27px;

        input {
          font-size: 15px;
        }
      }

      .card-label {
        margin-left: 27px;
        font-size: 16px;

        span {
          font-weight: bold;
          color: $primary;
        }
      }
    }

    .congratulations-modal {
      .modal {
        margin: 0 25px;
        padding: 65px 20px;

        .title {
          font-size: 31px;
          margin-bottom: 0;
        }

        .description {
          font-size: 16px;
          text-align: center;
          max-width: 250px;
          margin-bottom: 30px;
        }

        .activation-code {
          max-width: 245px;
          padding: 12px;
          margin-bottom: 25px;

          .code-wrapper {
            font-size: 14px;
          }
        }
      }
    }

  }

  @media only screen and (max-width: 375px) {
    .product-wrapper {
      .product-description {
        .product-description-mobile {
          max-width: 320px;

          .title {
            max-width: 320px;
          }
        }
      }
    }

    .tabs-wrapper {
      .tab-headers {
        .tab-header {
          padding-left: 0;
          padding-right: 0;

          .tab-title {
            text-align: center;
          }
        }

        .tab-header:first-child {
          padding-left: 25px;
        }

        .tab-header:last-child {
          padding-right: 25px;
        }
      }
    }

    .tabs-wrapper {
      .tabs-content {
        .tab-2 {
          .tab-icon-wrapper {
            font-size: 20px;
            padding: 0 0 0 29px;
          }
        }

        .tab-price-wrapper {
          max-width: 280px;
        }

        .price-wrapper {
          display: flex;
          width: 100%;
          max-width: 81px;
          align-items: center;
          margin: 0 25px 0 0;
        }

        .device-wrapper {
          max-width: 280px;
        }

        .buttons-wrapper {
          max-width: 280px;

          &.flex-end {
            margin: 8px 28px 35px 25px;
            padding: 0;
          }

          &.background {
            padding: 0;
            margin: 0 20px 35px 20px;

          }

          .next {
            margin-left: 5px;
          }

          .back {
            margin-right: 5px;
          }
        }
      }
    }
  }
}


