@import '../../../styles/shared';

.header-wrapper {
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: $black;
  color: $white;
  display: flex;

  .separator {
    padding: 0 6px;
  }

  .active-language {
    color: $primary;
  }

  .globe-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;

    path {
      fill: $white;
    }
  }

  .link {
    font-size: 16px;
    text-decoration: none;
    color: $white;
  }

  .logo {
    height: 14px;
  }
}
