.padding-wrapper {
  padding-left: 15%;
  padding-right: 15%;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-justify-evenly {
  justify-content: space-evenly;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}


@media only screen and (max-width: 768px) {
  .padding-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
