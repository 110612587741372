@import '../../../styles/shared';

.footer-wrapper {

  .color-primary {
    color: $primary;
  }

  .link {
    padding-top: 2px;
    padding-bottom: 2px;
    text-transform: none;
  }

  .logo {
    width: 200px;
  }

  .bg-lightgray {
    background-color: $light-grey;
  }

  .bg-black {
    background-color: $black;
  }

  .fill-white {
    svg {
      width: 17px;

      path {
        fill: $white;
      }
    }

    &.link {
      display: flex;
      align-items: center;
      padding: 12px 15px;
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    color: $white;
  }
}

@media only screen and (max-width: 768px) {
  .footer-wrapper {

    .color-primary {
      color: $primary;
    }

    .w-100 {
      width: 100%;
    }

    .link {
      padding-top: 2px;
      padding-bottom: 2px;
      text-transform: none;
    }

    .logo {
      width: 200px;
    }

    .bg-lightgray {
      background-color: $light-grey;
    }

    .bg-black {
      background-color: $black;
    }

    .fill-white {
      svg {
        width: 17px;

        path {
          fill: $white;
        }
      }

      &.link {
        display: flex;
        align-items: center;
        padding: 12px 15px;
      }
    }

    .copyright {
      display: flex;
      align-items: center;
      color: $white;
    }
  }
}

@media only screen and (max-width: 576px) {
  .footer-wrapper {
    .bg-lightgray {
      background-color: $black;
    }

    .logo {
      height: 14px;
      width: auto;
    }

    .bg-black {
      flex-direction: column-reverse;
      align-items: center;
    }

    .copyright {
      margin: 10px 0px 10px 0px;
      color: $white;
      opacity: 50%;
      align-self: flex-start;
    }
  }
}
