@import '../../../styles/shared';

.stripe-form {
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    font-size: 1em;
    border: 1px solid rgba($grey, 0.3);
  }

  @media only screen and (max-width: 768px) {
    padding: 0 27px 0 27px;
    .grouped {
      display: flex;
      flex-direction: row !important;

      .StripeElement {
        flex: 1;

        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}
