@import '../../../styles/shared';

.calendar-wrapper {
  position: relative;

  .custom-selected {
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .calendar {
    position: absolute;
    width: calc(100% - 2px);
    background-color: $white;
    border: 1px solid rgba($grey, 0.3);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 15px 0;
    height: 335px;


    .calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0 auto;

      .month {
        font-size: 23px;
        margin: 0;
      }

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;

          &.left,
          &.right {
            path {
              fill: $dark-grey;
            }
          }

          &.left {
            margin-left: 25px;
            transform: rotate(180deg);
          }

          &.right {
            margin-right: 25px;
          }
        }
      }
    }

    .calendar-content {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      text-align: center;

      div {
        padding: 8px;
        margin: 0 auto;
        font-weight: bold;

        &.cursor-pointer {
          cursor: pointer;
        }

        &.day-of-week {
          font-size: 10px;
          color: $grey;
        }

        &.date {
          padding-bottom: 0;
        }
      }

      .dots {
        padding: 0;

        .dot {
          padding: 0;
          height: 7px;
        }
      }
    }

    .prev, .next {
      color: $dark-grey;
      opacity: 0.5;
    }

    .selected {
      color: $white;
      background-color: $primary;
      border-radius: 5px;
    }
  }

  @media only screen and (max-width: 425px) {
    .calendar {
      &:first-child {
        margin-right: 0;
      }
      .calendar-content {
        div {
          padding: 6px;
        }
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .calendar {
      &:first-child {
        margin-right: 0;
      }
      .calendar-content {
        div {
          padding: 6px;
        }
      }
    }
  }

  @media only screen and (max-width: 568px) {
    .calendar {
      &:first-child {
        margin-right: 0;
      }
      .calendar-content {
        div {
          padding: 6px;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
      .calendar {
        &:first-child {
          margin-right: 0;
        }
        .calendar-content {
          div {
            padding: 6px;
          }
        }
      }
  }
}




